#skills{
    overflow: hidden;
    width: 100vw;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin:0 auto;

}

.skillTitle{
    font-size: 3rem;
    font-weight: 600;
    padding-top: 3rem;
    margin-bottom: 1.5rem;
 
}


.skillDesc{
    font-size: 0.9rem;
    font-weight: 300;
    max-width: 55rem;
    padding: 0 1rem;
    margin-bottom: 3rem;
}

.skillBars{
    margin: 1.5rem 0;
    width:100vw;
    max-width: 85%;
    text-align: left;
}

.skillBar{
    display: flex;
    margin: 1rem;
    padding: 1.5rem 2rem;
    border-radius: 1rem;
    background: rgb(50,50,50);
}


.skillBarImg{
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin-right: 2rem;
}

.skillBarText>p{
    font-size: 0.9rem;
    font-weight:200;
}


@media screen and (max-width: 720px){
    .skillBarText>p{
        font-size: 2vw;
    }
    
}

@media screen and (max-width: 480px){
    .skillDesc{
        max-width: 100vw;
    }
    .skillBarText>p{
        font-size: 4vw;
    }
    .skillBarImg{
        height: 2rem;
        width: 2rem;
    }
    .skillBarText{
        font-size: 5vw;
     
    }
    
}