.navbar{
    background: rgb(30,30,30);
    height: 5rem;
    width: 100vw;   /* 100% of the viewport width */
    max-width: 90rem;   /* 75rem = 1200px */
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
}

.logo{
    object-fit: cover;
    height: 3rem;
    width: 100vw;
    max-width: 20vw;
}

.desktopMenuListItem{
    margin: 1rem;
    cursor:pointer;


}
.desktopMenuListItem:hover{
    color:rgb(221, 44, 168);
    padding-bottom: 0.5rem;
    border-bottom: rgb(221, 44, 168);
}

.destopMenuBtn{
    background: white;
    color: #000;
    border:none;
    display:flex;
    align-items: center;
    justify-content: center;        
    padding: 0 1rem;
    border-radius: 2rem;
    cursor: pointer;
    height: 2.5rem;
}

.desktopMenuImg{
    object-fit:cover;
    height: 1rem;
    width: 1rem;
    margin: 1rem;
}

.active{
    color: rgb(221, 44, 168);;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid rgb(221, 44, 168);

}

.mobMenu{
    display: none;
    object-fit: cover;
    height: 3rem;

}
.navMenu{
    position: absolute;
    top: 5rem;
    right: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: fit-content;
    min-width: 15rem;
    background: rgb(60,60,60);
    border-radius: 1rem;
}

.listItem{
    padding: 0.5rem 3rem;
    margin: 0.25rem;
    background:rgb(30,30,30) ;
}

.beeLogo{
    display: none;
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin: 0.5rem;
}

@media screen and (max-width: 720px){
    .desktopMenu{
        display: none;
    }

    .mobMenu{
        display: block;
    }
    .navMenu{
        display: none;
    }   
    .destopMenuBtn{
        display: none;
    }
}
.contactImg{
    object-fit: cover;
    height: 2.5rem;
    width: 1rem;
    margin: 0.8rem;
}
@media screen and (max-width: 1480px){
    .logo{
       display: none;
    }
    .beeLogo{
        display: block;
    }
}
