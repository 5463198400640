#works{
    margin: 0 auto;
    min-height: (100vh - 4rem);
    width: 100vw;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;


}

.worksTitle{
    font-size: 2rem;
    margin: 1rem 0;
}

.worksDesc{
    font-weight: 300;
    font-size: 1rem;
    max-width: 45rem;
}

.worksImgs{
    display: flex;
    align-items: center;
    justify-self: center;
    width: 100vw;
    max-width: 50rem;
    flex-wrap: wrap;
    margin: 1rem;
    margin-left: 10rem;
}

.worksImg{
    object-fit: cover;
    height: 25rem;
    width: 100%;
    max-width: 20rem;
    margin: 0.5rem;

}


.worksBtn{
    margin: 3rem 0;
    padding: 0.5rem 2.5rem;
    border: none;
    border-radius: 2rem;
    background-color:white;
    cursor: pointer;
    font-size: 1rem;
   
}

@media  screen and (max-width: 585px){
    .worksImg{
        height: 48vw;
    }
}